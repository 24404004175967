<template>
    <div>
        <bread></bread>
        <!--    内容    -->
        <div class="center-content">
            <div class="iq-card-body">
                <div class="table-top">
                    <el-button type="primary" size="small" @click="Add()" plain>新增</el-button>
                </div>
                <el-table :data="InvoiceData"
                          stripe
                          style="width: 100%" :header-cell-style="{background:'#F5F7FC'}">
                    <el-table-column prop="name"
                                     label="企业名称">
                    </el-table-column>
                    <el-table-column prop="invoiceNo"
                                     label="税号">
                    </el-table-column>
                    <el-table-column prop="address"
                                     label="地址">
                    </el-table-column>
                    <el-table-column prop="phone"
                                     label="电话">
                    </el-table-column>
                    <el-table-column prop="bank"
                                     label="开户行">
                    </el-table-column>
                    <el-table-column prop="bankNo"
                                     label="开户账号">
                    </el-table-column>
                    <el-table-column fixed="right"
                                     label="操作" width="220">
                        <template slot-scope="scope">
                            <span class="info optionBtn" @click="EditBtn(scope.row)">编辑</span>
                            <span class="danger optionBtn" @click="RemoveBtn(scope.row)">删除</span>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>
        <el-dialog :title="DialogTitle" :visible.sync="setDialog">
            <el-form class="dialog-form" :model="invoiceForm" ref="invoiceForm" :label-width="formLabelWidth">
                <!-- <el-form-item label="企业名称" prop="select">
                    <el-radio-group v-model="invoiceForm.select">
                        <el-radio label="机构"></el-radio>
                        <el-radio label="个人"></el-radio>
                    </el-radio-group>
                </el-form-item> -->
                <el-form-item label="企业名称" prop="name">
                    <el-input v-model="invoiceForm.name" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="税号" prop="invoiceNo">
                    <el-input v-model="invoiceForm.invoiceNo " autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="地址" prop="address">
                    <el-input v-model="invoiceForm.address" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="电话" prop="phone">
                    <el-input v-model="invoiceForm.phone" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="开户行" prop="Bank">
                    <el-input v-model="invoiceForm.Bank" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="开户账号" prop="BankNo">
                    <el-input v-model="invoiceForm.BankNo" autocomplete="off"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="CloseBtn('AddDepartmentInfo')">取 消</el-button>
                <el-button type="primary" @click="SaveBtn('AddDepartmentInfo')">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import bread from "../../../components/common/breadcrumb"
    import {Invoice} from "../../../components/HospitalDomain/Invoice";
    export default {
        name: "Invoice",
        components: {
            bread
        },
        data(){
            var invoice = new Invoice(this.TokenClient, this.Services.Authorization)
            return{
                Invoice: invoice,
                organizationId: JSON.parse(sessionStorage.getItem('userInfo')).organizationId,
                UserId:  JSON.parse(sessionStorage.getItem('userInfo')).id,
                InvoiceData: [],
                setDialog: false,
                DialogTitle: '新增发票信息',
                invoiceForm:{
                    name: '',
                    invoiceNo: '',
                    address: '',
                    phone: '',
                    Bank: '',
                    BankNo: '',
                    oranizationId:null,
                    operationUserId: null,
                },
                formLabelWidth: '120px',
                organizationData:[]
            }
        },
        mounted() {
            this.getInvoiceInfo();
        },
        methods:{
            getInvoiceInfo(){
                var _this = this;
                _this.InvoiceData = [];
                _this.Invoice.InvoiceInfo(this.organizationId,function (data) {
                    _this.InvoiceData = data.data;
                }, function (err) { console.log(err); });
            },
            Add(){
                this.setDialog = true;
                this.DialogTitle = '新增发票信息';
                this.invoiceForm = {
                    name: '',
                    invoiceNo: '',
                    address: '',
                    phone: '',
                    Bank: '',
                    BankNo: '',
                    organizationId:this.organizationId,
                    operationUserId: this.UserId,
                }
            },
            EditBtn(item){
                this.setDialog = true;
                this.DialogTitle = '编辑发票信息';
                this.invoiceForm = {
                    id: item.id,
                    name: item.name,
                    invoiceNo: item.invoiceNo,
                    address: item.address,
                    phone: item.phone,
                    Bank: item.bank,
                    BankNo: item.bankNo,
                    organizationId:this.organizationId,
                    operationUserId: this.UserId,
                }
            },
            SaveBtn(){
                var _this = this
                if(this.invoiceForm.id > 0){
                    _this.Invoice.Edit(_this.invoiceForm ,function (data) {
                        _this.$message({
                            type: 'success',
                            message: '修改成功!'
                        });
                        _this.setDialog = false
                        _this.getInvoiceInfo()
                    },function (err) { console.log(err); });
                }else {
                    _this.Invoice.InvoiceInfoAdd(_this.invoiceForm ,function (data) {
                        _this.$message({
                            type: 'success',
                            message: '添加成功!'
                        });
                        _this.setDialog = false
                        _this.getInvoiceInfo()
                    },function (err) { console.log(err); });
                }
            },
            RemoveBtn(e){
                console.log(e)
                var _this = this;
                _this.$confirm('是否删除此信息?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    // type: 'warning'
                    }).then(() => {
                        _this.Invoice.InvoiceInfoRemove(e.id,
                        function (data) {
                            _this.$message({
                                type: 'success',
                                message: '删除成功!'
                            });
                            _this.getInvoiceInfo()
                        },
                        function (err) { console.log(err); });
                    }).catch(() => {

                    });
            },
            CloseBtn(formName){
                this.setDialog = false;
                this.$refs[formName].resetFields();
            }
        }
    }
</script>

<style scoped>
    .dialog-form {
        width: 85%;
    }
    .table-top {
    margin-bottom: 15px;
}
</style>
